.landingpage
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landingcontents
{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 45px;
    /* justify-content: center; */


}

.landh1
{
    font-size: 32px;
    margin-bottom: 40px;
}

.landcontent
{
    margin-bottom: 20px;
    font-size: 18px;
}

.landcontent2
{
    margin-bottom: 30px;
    font-size: 18px;

}

.landingcontents button{
    /* color: #1890ff; */
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 7px 35px;
    font-size: 18px;
    margin-left: 20px;
    cursor: pointer;

}