.tablecontainer
{
    width: 92vw;
}

.tablepage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}