.logincontainer
{
    width: 100vw;
    align-items: center;
    display: flex;
    justify-content: center;
}

.logininner
{
    margin-top: 90px;
    width: 450px;
    /* background-color: cadetblue; */
    height:350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.gplunder
{
    width: 50px;
    height: 5px;
    margin-bottom: 20px;
    background-color: #0d76d8;
    border-radius: 5px;
}

.loginpbutton
{
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 5px 29px;
    font-size: 18px;
    /* margin-left: 20px; */
    cursor: pointer;
    width: 100px;
    margin-top: 20px;
    
}

.loginheader
{
    font-size: 22px;
    
}
